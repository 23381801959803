import type { NuxtApp } from "#app/nuxt";
import { AsyncDataOptions } from "#app/composables/asyncData";

export function useAsyncCachedData<ResT>(
  key: MaybeRef<string>,
  handler: (ctx?: NuxtApp) => Promise<ResT>,
  options?: AsyncDataOptions<ResT>,
) {
  const maxAge = 1000 * 60 * 10; // 10 minutes
  const nuxtApp = useNuxtApp();

  return useAsyncData<ResT>(
    unref(key),
    async () => {
      if (!nuxtApp.payload.data?.cache) {
        nuxtApp.payload.data.cache = {};
      }
      const data = nuxtApp.payload.data.cache[unref(key)];

      if (!data) {
        const result = await handler();
        nuxtApp!.payload.data.cache[unref(key)] = result;
        return result;
      }

      const expirationDate = new Date(data.fetchedAt);
      expirationDate.setTime(expirationDate.getTime() + maxAge);
      const isExpired = expirationDate.getTime() < Date.now();
      if (isExpired) {
        const result = await handler();
        nuxtApp!.payload.data.cache[unref(key)] = result;
        return result;
      }
      return data;
    },
    {
      // getCachedData(key) {
      //   const data = nuxtApp.payload.data[key] || nuxtApp.static.data[key];
      //
      //   if (!data) {
      //     return;
      //   }
      //
      //   const expirationDate = new Date(data.fetchedAt);
      //   expirationDate.setTime(expirationDate.getTime() + maxAge);
      //   const isExpired = expirationDate.getTime() < Date.now();
      //   if (isExpired) {
      //     return;
      //   }
      //
      //   return data;
      // },
      ...(options || {}),
    },
  );
}
